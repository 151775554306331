import sizes from "./sizes";
import global from "./global";
export default {
  global,
  content: {
    padding: "224px 100px",
    margin: "0 auto",
  },
  grad: {
    backgroundImage: "linear-gradient(to right, #1e202c, transparent)",
    height: "inherit",
  },
  cta: {
    backgroundImage: (props) =>
      props.attrs.image ? `url(${props.attrs.image})` : "",
    height: "600px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: (props) => (props.attrs.dark ? "#fff" : "#1e202c"),
    [sizes.down("sm")]: {
      height: "auto",
    },
  },
  link: {
    marginTop: "32px",
    borderRadius: "100px !important",
    textAlign: "center !important",
    font: "normal normal 600 20px/30px 'Graphie' !important",
    letterSpacing: "0px !important",
    padding: "8px 16px !important",
    border: (props) =>
      props.attrs.dark ? "1px solid #fff" : "1px solid #1e202c",
    color: (props) => (props.attrs.dark ? "#fff" : "#1e202c"),
    background: "transparent",
    width: "15%",
    justifySelf: "flex-end",
    marginRight: "50px",
    "&:hover": {
      background: (props) =>
        props.attrs.dark
          ? "#fff 0% 0% no-repeat padding-box"
          : "#1e202c 0% 0% no-repeat padding-box",
      color: (props) => (props.attrs.dark ? "#1e202c" : "#fff"),
    },
    "&:active": {
      background: "#1e202c 0% 0% no-repeat padding-box",
      color: "#cfd2e6",
    },
    "&:disabled": {
      background: "#fcfafa 0% 0% no-repeat padding-box",
      border: "1px solid #f0eeed",
      color: "#bdb9b9",
    },
    "&:focus": {
      background: (props) =>
        props.attrs.dark
          ? "#fff 0% 0% no-repeat padding-box"
          : "#1e202c 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 6px #0058ff81",
      border: "1px solid #1230e6",
      color: (props) => (props.attrs.dark ? "#1e202c" : "#fff"),
    },
    "& span": {
      backgroundColor: "inherit",
    },
  },
};
