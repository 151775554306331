import sizes from "./sizes";
import global from "./global";
export default {
global,
  media:{
    height: "455px",
    width:"540px"
  },
  modCont:{
  width: "65%",
  margin: "auto",
  height: "100%",
  alignContent: "center",
  },
  col:{
    backgroundColor:"#fff"
  },
  textBox:{
    height: "89%",
    alignContent: "center",
    display: "grid",
  },
  info:{
    padding:"0 50px 0 0"
  },
  btnBox:{
    display:"grid"
  },
  btn: {
    borderRadius: "100px !important",
    textAlign: "center !important",
    font: "normal normal 600 20px/30px 'Graphie' !important",
    letterSpacing: "0px !important",
    padding: "8px 16px !important",
    border: "1px solid #1e202c",
    color: "#1e202c",
    background: "transparent",
    width:"35%",
    justifySelf:"flex-end",
    marginRight:"50px",
    "&:hover": {
      background: "#1e202c 0% 0% no-repeat padding-box",
      color: "#ffffff !important",
    },
    "&:active": {
      background: "#1e202c 0% 0% no-repeat padding-box",
      color: "#cfd2e6",
    },
    "&:disabled": {
      background: "#fcfafa 0% 0% no-repeat padding-box",
      border: "1px solid #f0eeed",
      color: "#bdb9b9",
    },
    "&:focus": {
      background: "#ffffff 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 6px #0058ff81",
      border: "1px solid #1230e6",
      color: "#1e202c!important",
    },
    "& span":{
        backgroundColor:"inherit"
    }
  },
}