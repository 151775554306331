import React, { useState, useEffect } from "react";
import axios from "axios";
import Nav from "../components/nav";
import Page from "./page";
import { useParams } from "react-router-dom";

function Root() {
  const [slug, setSlug] = useState(null);
  const [page, setPage] = useState(null);
  const [menu, setMenu] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dark, setDark] = useState(false);

  let { parent } = useParams();

  if(parent !== slug){
    setSlug(parent)
  }

  useEffect(() => {
    async function getPage() {
      if (parent === undefined) {
        const response = await axios.get(
          `https://autodeskhighlights.torpedo.agency/wp-json/wp/v2/pages/?slug=home`
        );
        setPage(response.data[0]);
        setDark(response.data[0].gblocks[0].attrs.dark);
      } else {
        const response = await axios.get(
          `https://autodeskhighlights.torpedo.agency/wp-json/wp/v2/pages/?slug=${parent}`
          
        );
        setPage(response.data[0]);
        setDark(response.data[0].gblocks[0].attrs.dark);
      }
    }
    getPage();
    async function getMenu() {
      const response = await axios.get(
        `https://autodeskhighlights.torpedo.agency//wp-json/wp/v2/pages?orderby=menu_order&order=asc&_fields[]=title&_fields[]=menu_order&_fields[]=id&_fields[]=parent&_fields[]=slug&per_page=99`
      );
      setMenu(response.data);
      setLoading(false);
    }
    getMenu();
  }, [slug]);
    return (
      <div>
       { menu && <Nav dark={dark} menu={menu} />}
      { page && <Page page={page} />}
      </div>
    );
  } 
export default Root;
