import sizes from "./sizes";
import global from "./global";
export default {
  global,
  media: {
    height: "340px",
  },
  over: {
    textAlign: "left",
    position: "absolute",
    backgroundImage: "linear-gradient(transparent,#000)",
    height: "100%",
    width: "100%",
  },
  text: {
    position: "absolute",
    bottom: "10px",
    left: "10px",
    color:"#fff",
    "& button":{
        color:"#fff"
    }
  },
};
