import { withStyles } from "@material-ui/styles";
import styles from "../styles/case";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import uuid from 'react-uuid';

function CaseStudies(props) {
  const { classes } = props;
  const cases = props.cases.map((p, index) => {
    return (
      <Grid key={uuid()} item lg={4}>
        <Card className={classes.root}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image={p.acf.image}
              title={p.acf.title}
            >
              <div className={classes.over}>
                <div className={classes.text}>
                  <Typography gutterBottom variant="h5" component="h4">
                    {p.acf.title}
                  </Typography>{" "}
                  <Button size="small" color="primary">
                    View Project
                  </Button>
                  </div>
              </div>
            </CardMedia>
          </CardActionArea>
        </Card>
      </Grid>
    );
  });
  return (
    <Grid container spacing={3}>
      {cases}
    </Grid>
  );
}

export default withStyles(styles)(CaseStudies);
