import { withStyles } from "@material-ui/styles";
import classNames from "classnames";
import styles from "../styles/cta";

function CTA(props) {
  const { classes } = props;
  const { title, subtitle } = props.attrs;
  return (
    <div className={classes.cta}>
      <div className={classes.grad}>
        <div class={classes.content}>
          <h2>{title}</h2>
          <h3>{subtitle}</h3>
          <button className={classes.link}>Get in Touch</button>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(CTA);
