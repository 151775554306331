import { withStyles } from "@material-ui/styles";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import React, { useState } from "react";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import uuid from "react-uuid";
import styles from "../styles/people";

function People(props) {
  const [open, setOpen] = useState(false);
  const [mod, setMod] = useState(null);
  function handleOpen(val) {
    setMod(val);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  let body;
  if (mod === null) {
    body = <div></div>;
  } else {
    const { classes } = props;
    body = (
      <Grid container spacing={3} className={classes.modCont}>
        <Grid key={uuid()} item lg={6} className={classes.col}>
          <img src={mod.picture} />

          <h2 id="simple-modal-title">{mod.person_name}</h2>
          <p id="simple-modal-description">{mod.job_title}</p>
        </Grid>
        <Grid key={uuid()} item lg={6} className={classes.col}>
          <div className={classes.textBox}>
            <div
              className={classes.info}
              dangerouslySetInnerHTML={{ __html: mod.info }}
            ></div>
          </div>
          <div className={classes.btnBox}>
            <button className={classes.btn} onClick={handleClose}>
              Close
            </button>
          </div>
        </Grid>
      </Grid>
    );
  }

  const { classes } = props;

  const people = props.people.map((p, index) => {
    return (
      <Grid key={uuid()} item lg={4}>
        <Card className={classes.root}>
          <CardActionArea onClick={(e) => handleOpen(p.acf, e)}>
            <CardMedia
              className={classes.media}
              image={p.acf.picture}
              title={p.acf.person_name}
            ></CardMedia>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h4">
                {p.acf.person_name}
              </Typography>
              <Typography gutterBottom variant="h5" component="p">
                {p.acf.job_title}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    );
  });
  return (
    <Grid container spacing={3}>
      {people}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </Grid>
  );
}

export default withStyles(styles)(People);
