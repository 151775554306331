import sizes from "./sizes";
import global from "./global";
export default {
  global,
  dark: {
    backgroundColor: (props) => (props.attrs.dark ? "#1e202c" : "#F0EEED"),
  },
  content: {
    padding: "224px 100px",
    margin: "0 auto",
    "& video": {
      width: "100%",
    },
    "& img": {
      width: "100%",
    },

    "& textBox": {
      padding: "0 35px",
      margin:"auto"
    },
    "& title": {
      padding: "20px 0",
    },
    "& titleTop": {
      padding: "0 0 20px 50px",
    },
    "& text": {
      padding: "20px 0 0",
    },
    [sizes.down("md")]: {
      padding: "50px 50px",
    },
    [sizes.down("xs")]: {
      padding: "50px 5px",
    },
  },
};
