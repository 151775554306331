import sizes from "./sizes";
export default {
  root: {
    fontFamily: "Graphie",
    "& dark": {
      color: "#fff !important",
      "& p": {
        color: "#fff",
      },
    },
    "& light": {
      color: "#1e202c !important",
    },
    "& panel": {
      "& dark": {
        backgroundColor: "#1e202c;",
      },
      "& light": {
        backgroundColor: "#F0EEED",
      },
    },
    "& bold": {
      fontWeight: "600 !important",
    },
    "& p": {
      font: "normal normal normal 20px/30px 'Graphie'",
      color: "#666666",
      letterSpacing: "0px",
    },

    "& h1": {
      font: "normal normal 600 56px/67px 'Graphie' !important",
      letterSpacing: "0px",
      [sizes.down("xs")]: {
        font: "normal normal normal 46px/57px 'Graphie' !important",
        letterSpacing: "0px",
      },
    },
    "& h2": {
      font: "normal normal 600 48px/58px 'Graphie'",
      letterSpacing: "0px",
    },
    "& h3": {
      font: "normal normal 600 40px/48px 'Graphie'",
      letterSpacing: "0px",
    },
    "& h4": {
      font: "normal normal 600 32px/38px 'Graphie'",
      letterSpacing: "0px",
    },
    "& h5": {
      font: "normal normal 600 28px/34px 'Graphie'",
      letterSpacing: "0px",
    },
    "& h6": {
      font: "normal normal 600 24px/29px 'Graphie'",
      letterSpacing: "0px",
    },
    "& subTitle": {
      font: "normal normal 600 24px/36px 'Graphie'",
      letterSpacing: "0px",
    },
    "& btn": {
      borderRadius: "100px !important",
      textAlign: "center !important",
      font: "normal normal 600 20px/30px 'Graphie' !important",
      letterSpacing: "0px !important",
      padding: "8px 16px !important",
      "& light": {
        border: "1px solid #1e202c",
        color: "#1e202c",
        background: "transparent",
      },
      "& light:hover": {
        background: "#1e202c 0% 0% no-repeat padding-box",
        color: "#ffffff !important",
      },
      "& light:active": {
        background: "#1e202c 0% 0% no-repeat padding-box",
        color: "#cfd2e6",
      },
      "& light:disabled": {
        background: "#fcfafa 0% 0% no-repeat padding-box",
        border: "1px solid #f0eeed",
        color: "#bdb9b9",
      },
      "& light:focus": {
        background: "#ffffff 0% 0% no-repeat padding-box",
        boxShadow: "0px 0px 6px #0058ff81",
        border: "1px solid #1230e6",
        color: "#1e202c!important",
      },
      "& dark": {
        border: "1px solid #fff",
        color: "#fff",
        background: "transparent",
      },
      "& dark:hover": {
        background: "#fff 0% 0% no-repeat padding-box",
        color: "#1e202c !important",
      },
      "& dark:active": {
        background: "#fff 0% 0% no-repeat padding-box",
        color: "#686f99",
      },
      "& dark:disabled": {
        background: "#575656 0% 0% no-repeat padding-box",
        border: "1px solid #f0eeed",
        color: "#a3a0a0",
      },
      "& dark:focus": {
        background: "#1e202c 0% 0% no-repeat padding-box",
        boxShadow: "0px 0px 6px #0058ff81",
        border: "1px solid #1230e6",
        color: "#fff",
      },
    },
    "& tags": {
      background: "#1e202c",
      font: "normal normal normal 12px/14px 'Graphie'",
      letterSpacing: "0px",
      color: "#ffffff",
      padding: "4px 4px",
      borderRadius: "4px",
    },
  },
};
