import React, { useState, useEffect } from "react";
import axios from "axios";
import { withStyles } from "@material-ui/styles";
import styles from "../styles/cardThree";
import CaseStudies from "./caseStudies";
import People from "./people";

function CardThree(props) {
  const [sel, setSel] = useState(null);
  const [loading, setLoading] = useState(null);
  let l;
  if (props.attrs.limit === true) {
    l = 3;
  } else {
    l = 99;
  }

  useEffect(() => {
    async function getCase() {
      let t;
      if(props.attrs.people === true){
        t = `people?per_page=${l}&orderby=menu_order`
      }
      else{
        t = `case-studies?per_page=${l}`
      }
      const response = await axios.get(
        `https://autodeskhighlights.torpedo.agency/wp-json/wp/v2/${t}`
      );
      setSel(response.data);
      setLoading(false);
    }
    getCase();
  }, [loading]);

  const { people, title, text,limit } = props.attrs;
  const {classes} = props
  let type;
  if (sel && people === false) {
    type = <CaseStudies cases={sel}/>;
  } else if(sel && people === true) {
    type = <People limit={limit} people={sel}/>;
  }
  else {
    <div></div>
  }
  return (
    <div className={classes.cardSection}>
    <div className={classes.content}>
      <h2>{title}</h2>
      <div className={classes.text} dangerouslySetInnerHTML={{ __html: text }}></div>
      {type}
    </div>
    </div>
  );
}

export default withStyles(styles)(CardThree);
