import { withStyles } from "@material-ui/styles";
import classNames from "classnames";
import styles from "../styles/cols";
import Grid from "@material-ui/core/Grid";

function Cols(props) {
  const { classes } = props;
  const { title, text } = props.attrs;
  return (
    <div className={classes.content}>
      <Grid container spacing={3}>
        <Grid className={classes.title} item lg={6}>
          <h2 className={classes.space}>
            <bold>{ title }</bold>
          </h2>
          <p className={classes.space}>{ text }</p>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(Cols);
