import sizes from "./sizes";
import global from "./global";
export default {
  global,
  cardSection: {
    textAlign: "center",
    color: (props) => (props.attrs.dark ? "#fff" : "#1e202c"),
    backgroundColor: (props) => (props.attrs.dark ? "#1e202c" : "#fff"),
  },
  content: {
    padding: "224px 100px",
    margin: "0 auto",
    "& h2": {
        paddingBottom: (props) => (props.text === "" ? "56px" : "22px")
    },
  },
  text: {
    paddingBottom: "54px",
    maxWidth: "720px",
    margin: "auto",
  },
};
