import { withStyles } from "@material-ui/styles";
import styles from "../styles/textImg";
import Grid from "@material-ui/core/Grid";

function TextImg(props) {
  const { dark, add_more } = props.attrs;
  const { classes } = props;
  const addMore = add_more.map((b, index) => {
    let media;
    if (b.video === true) {
      media = (
        <video loop autoplay="true" muted>
          <source src={b.video_file} type="video/mp4" />
        </video>
      );
    } else {
      media = <img src={b.image}></img>;
    }
    return (
      <Grid container spacing={3}>
        <Grid item lg={6}>
          <div className={classes.textBox}>
            <h2>{b.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: b.text }}></div>
          </div>
        </Grid>
        <Grid item lg={6}>
          {media}
        </Grid>
      </Grid>
    );
  });
  return (
    <div className={classes.dark}>
      <div className={classes.content}>
        <h2></h2>
        {addMore}
      </div>
    </div>
  );
}

export default withStyles(styles)(TextImg);
