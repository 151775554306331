import sizes from "./sizes";
import global from "./global";
export default {
  global,
  root: {
    padding:"0 100px",
    flexGrow: 1,
    backgroundColor: (props) => (props.dark ? "transparent" : "#fff"),
    marginBottom: (props) => (props.dark ? "-64px" : "0"),
    position: "relative",
    zIndex: (props) => (props.dark ? "100" : "0"),
    color: (props) => (props.dark ? "fff!important" : "#1e202c!important"),
    "& header": {
      backgroundColor: (props) => (props.dark ? "transparent" : "#fff"),
      boxShadow: "none",
    },
    "& svg": {
      fill: (props) => (props.dark ? "#fff" : "#000"),
    },
    "& a": {
      alignSelf: "center",
      padding: "0 20px",
      color: (props) => (props.dark ? "#fff" : "#1e202c"),
      textDecoration: "none",
      "&:last-child": {
        marginTop: "32px",
        borderRadius: "100px !important",
        textAlign: "center !important",
        font: "normal normal 600 20px/30px 'Graphie' !important",
        letterSpacing: "0px !important",
        padding: "8px 16px !important",
        border: (props) =>
          props.dark ? "1px solid #fff" : "1px solid #1e202c",
        "&:hover": {
          background: (props) =>
            props.dark
              ? "#fff 0% 0% no-repeat padding-box"
              : "#1e202c 0% 0% no-repeat padding-box",
          color: (props) => (props.dark ? "#1e202c" : "#fff"),
        },
        "&:active": {
          background: "#1e202c 0% 0% no-repeat padding-box",
          color: "#cfd2e6",
        },
        "&:disabled": {
          background: "#fcfafa 0% 0% no-repeat padding-box",
          border: "1px solid #f0eeed",
          color: "#bdb9b9",
        },
        "&:focus": {
          background: (props) =>
            props.dark
              ? "#fff 0% 0% no-repeat padding-box"
              : "#1e202c 0% 0% no-repeat padding-box",
          boxShadow: "0px 0px 6px #0058ff81",
          border: "1px solid #1230e6",
          color: (props) => (props.dark ? "#1e202c" : "#fff"),
        },
      },
    },
  },
  title: {
    position: "absolute",
    right: "0",
  },
};
