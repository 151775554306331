import { withStyles } from "@material-ui/styles"
import classNames from "classnames";
import styles from "../styles/hero"


function Hero(props) {
  const {title, text,video, video_file, text_centre, image} = props.attrs
  const {classes} = props
  let media;
  if (video === true) {
    media = (
      <video className={classes.displayMedia} loop autoplay="true" muted>
        <source src={video_file} type="video/mp4" />
      </video>
    );
  } else {
    media = <img className={classes.displayMedia} src={image}></img>;
  }
  return (
      <div className={`${classes.back} ${classes.dark}`}>
        <div className={classNames(classes.title, {[classes.textCenter]: text_centre })}>
          <strong>
            <h1>{title}</h1>
          </strong>
          <div>{text}</div> 
        </div>
        {media}
      </div>
  );
}

export default withStyles(styles)(Hero);
