import sizes from "./sizes";
import global from "./global"
export default {
  global,
  dark: {
    color: (props) => (props.attrs.dark ? "#fff" : "#1e202c"),
    backgroundColor: (props) => (props.attrs.dark ? "#1e202c" : "#fff"),
    zIndex: "0",
  },
  displayMedia: {
    width: "100%",
    opacity: (props) => (props.attrs.op ? ".4" : "1" )
  },
  img: {
    width: "100%",
    objectFit: "cover",
  },
  video: {
    width: "100%",
    objectFit: "cover",
    marginBottom: "-7px",
  },
  op: {
    opacity: 0.4,
  },
  back: {
    position: "relative",
    maxHeight: "950px",
    overflow: "hidden",
  },
  title: {
    marginLeft: "230px",
    marginTop: "120px",
    textAlign: "left",
    maxWidth: "712px",
    zIndex: "1",
    [sizes.down("md")]: {
      marginLeft: "30px,",
      marginTop: "50px,",
    },
    [sizes.down("xs")]: {
      marginLeft: "0px",
    },
  },
  textCenter: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    marginLeft: "0",
    marginTop: "0",
    [sizes.down("xs")]: {
      position: "absolute",
      top: "25%",
      left: "25%",
      transform: "translate(-25%, -25%)",
      textAlign: "center",
      marginLeft: "0",
      marginTop: "0",
      width: "100%",
    },
  },
  pad: {
    padding: "0 100px",
    [sizes.down("md")]: {
      padding: "0 50px",
    },
    [sizes.down("xs")]: {
      padding: "0 10px",
    },
  },
  subTitile: {
    [sizes.down("md")]: {
      display: "none",
    },
  },
};
