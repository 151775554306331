import React from "react";
import { withStyles } from "@material-ui/styles";
import Hero from "../components/hero";
import TextImg from "../components/textImg";
import CardThree from "../components/cardsThree"
import CTA from "../components/cta"
import Cols from "../components/cols"
import styles from "../styles/global";
import uuid from 'react-uuid';


function Page(props) {
  const { classes, page } = props;
  const blocks = page.gblocks.map((p, index) => {
    if (p.blockName === "acf/hero") {
      return <Hero key={uuid()} attrs={p.attrs} />;
    } else if(p.blockName === "acf/text-image") {
      return <TextImg key={uuid()}  attrs={p.attrs}/>; 
    }
    else if(p.blockName === "acf/cards-three") {
      return <CardThree key={uuid()}  attrs={p.attrs}/>
    }
    else if(p.blockName === "acf/cta-block") {
      return <CTA key={uuid()}  attrs={p.attrs}/>
    }
    else if(p.blockName === "acf/columns") {
      return <Cols key={uuid()}  attrs={p.attrs}/>
    }
    else{
      return <div key={uuid()} attrs={p.attrs}>{p.blockName}</div>;
    } 
  });
  return <div className={classes.root}>{blocks}</div>;
}

export default withStyles(styles)(Page);
