import sizes from "./sizes";
import global from "./global";

export default {
  global,
  content: {
    padding: "224px 100px",
    margin: "0 auto",
    backgroundColor: (props) => (props.attrs.dark ? "#1e202c" : "#F0EEED"),
    color: (props) => (props.attrs.dark ? "#fff" : "#1e202c"),
  },
  title: {
    textAlign: "center",
    maxWidth: "712px",
    margin: "auto",
  },
  img: {
    width: "100%",
    height: "auto",
  },
  text: {
    minHeight: "116px",
  },
  space: {
    padding: "16px 0",
  },
};
